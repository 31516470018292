<template>
  <section
    id="hero"
    style="margin-bottom: 5px;"
  >
    <router-link
      to="/albanileria"
      style="text-decoration: none;"
    >
      <v-img
        :min-height="minHeight"
        :src="require('@/assets/herobuilder.jpg')"
        class="white--text"
        gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
      >
        <v-container class="fill-height px-4 py-12">
          <v-responsive
            class="d-flex align-center"
            height="100%"
            max-width="700"
            width="100%"
          >
            <base-heading title="Albañilería" />
            <base-body>
              Tanto para reparar cualquier infraestructura del hogar como para construir un hogar entero, vamos a necesitar un albañil y sus herramientas. Nosotros contamos con una amplia cantidad de "manitas" que solucionarán tus problemas.
            </base-body>

            <div
              :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
              class="d-flex flex-wrap"
            />
          </v-responsive>
        </v-container>
      </v-img>
    </router-link>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>

<style scoped>
</style>
